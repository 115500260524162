
import { useEffect, useState, useRef } from 'react'
import TextField from '@mui/material/TextField';
import { useLoaderData } from "react-router-dom";

import '../styles/utils.css'

import main, { format_cash, formatCash, Refresh, Update, number } from '../resources/code/utils'
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Fade from '@mui/material/Fade'
import ProfileImage from '../resources/icons/profile.png'

import { useOutletContext } from "react-router-dom";

import EnterSerialNumbers  from '../elements/enter_heater_number';
import Paper from '@mui/material/Paper'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Warning from '../elements/stove_debt';

export function loader(){
    return fetch(main.server + '/sales_information').then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  
export default function SellHeater(){
    const [data, setData ] = useState(useLoaderData())
   

    const [ serial_numbers, setSerialNumbers ] = useState([])
    const [start, setStart] = useState(false)
    const [customer, setCustomer] = useState('')
    const [customer_list, setCustomerList] = useState([])
    const [number_of_stoves, setNumberOfStoves] = useState(1)
   
    const [ customer_uid, setCustomerUID ] = useState('')
    const [enter_serial_numbers, set_enter_serial_numbers] = useState(false)
    const [setSnack, setAlert, username, user_uid, _actions_ , setNotAllowed ] = useOutletContext()
    const [ total, setTotal ] = useState(data.heater_price)
    const [debts, setDebts] = useState([])
    const [ total_debt, setTotalDebt ] = useState(0)
    const [ sale_date, setSaleDate ] = useState(new Date())


    const clientSearchRef = useRef(null)
    const [ check_actions, setCheckActions ]  = useState(false)

 

    
    
  

    function serchForClients(elm){
        setCustomer(elm.target.value)
  
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setCustomerList(res.data.client_list)
            }
        })
    }
    function handleQuantity(event){
        let value = event.target.value

        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if(Number(clean) || clean === '0'|| clean === ''){
                clean = Number(clean)
                setNumberOfStoves(Number(clean))
                setTotal(Number(data.heater_price) * clean)
               
            }
        }else{
            if(Number(value) || value === '0'|| value === ''){
                value = Number(value)
                setNumberOfStoves(Number(value))
                setTotal(Number(data.heater_price) * value)
            } 
        } 

    }
    const required_stovSale_inputs = [
    
        { input: number_of_stoves },
     
        { input: customer_uid },
      
    ]
    
    function validate_heaterSale_Form(){
        let incomplete = false
        for(const input of required_stovSale_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }
    function ProceedToSerialNumbers(){
        if(!validate_heaterSale_Form()){
            set_enter_serial_numbers(true)
        }
    }
    function sellHeaters(){
        if(!validate_heaterSale_Form() && serial_numbers.length > 0){
            const form = new FormData()

            const f = {
                quantity: number_of_stoves,
              
                client: customer_uid,
                sold_by: username,
                serial_numbers:serial_numbers,
                date_sold: sale_date.toDateString(),
            }
            

            form.append('form', JSON.stringify(f))


            fetch(main.server + '/process_heater_sale',
                {
                    method: "POST",
                    body:form,
                }
            ).then( response =>{
                if(response.ok){
                    return response.json()
                }
                throw response
            }).then(res => {
                if(res.request_status){
                    setAlert({ message:'sale proccessed', type:'success'})
                    Refresh()
                }else{
                    setAlert({ message:res.message, type:'error'})
                }
            })
        }
    
    }
    function selectClient(name, uid){
        setCustomer(name)
        setCustomerUID(uid)
   
        setCustomerList([])
        fetch(main.server + `/get_client_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setDebts(result.data.debts)
            let total_debt = 0
            for(const debt of result.data.debts){
                total_debt += Number(debt.heater_price) - Number(debt.amount_paid)
            }
            setTotalDebt(total_debt)
        })
    }
    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setCustomerList([])
        }
        
    }

    useEffect(() => {
        if(!start){
            setSnack({
                message: 'page loaded',
            })
            setStart(true)
        }
       
        if(_actions_.length > 0 && !check_actions){

            if(_actions_.indexOf('sell_stoves') !== -1){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
            setCheckActions(true)
        }
        
    })
    return(
        <Fade in={true}>
            <div className="page" onClick={handleClicks}>
                <div className="system_title">
                    <div>Sell Heaters</div>
                </div>

                <div className="flex_column_center">
                    
                    
                    { debts.length > 0 ? 
                        <Warning debts={debts}/>
                    :''}

                    <div className="form_main form_centered">
                       
                        <div className="form_section">
                        
                            <div className="form_section_main">
                                <div>
                                    <div className="authenticatorTitle">Sold by:</div>
                                    <div>
                                        <div className="authenticator_final" >
                                            
                                            <div className="flex_row flex_align_center">
                                                <div className="authenticator_avatar">
                                                    <img src={ProfileImage} alt="user image"/>
                                                </div>
                                                <div className="padh10">{username}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div>
                              
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            label="Date of sale"
                                            value={sale_date}
                                            variant="standard"
                                            onChange={(newstartDate) => {
                                                setSaleDate(newstartDate)
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </div>
                                
                            </div>
                        </div>
                        
                        <div className="form_section">
                            <div className="form_section_main">
                                <div><TextField value={formatCash(data.heater_price)} sx={{width: 250}} className="text_field" id="standard-basic" label="Heater price" variant="standard" /></div>

                                <div className="">
                                    <TextField value={customer} onChange={serchForClients} sx={{width: 250}} className="text_field" id="standard-basic" label="Customer" variant="standard" />
                                


                                    <div className="filterSearchRes">
                                        {customer_list.length > 0 ?
                                            <Paper elevation={1} ref={clientSearchRef}  className="searchRBody searchSys" id="search_res_clients">
                                                {customer_list.map((elm,ind) => (
                                                    <div className="generalSearchResult searchSys" data-uid={elm.UID} onClick={() => selectClient(elm.name, elm.UID)} key={ind}>
                                                        {elm.name}
                                                    </div>
                                                ))}
                                            
                                            </Paper>
                                        :''}

                                    
                                    
                                    </div>

                                    
                                </div>
                            </div>
                        </div>

                        <div className="form_section">
                            <div className="form_section_main">
                              
                                <div><TextField value={format_cash(number_of_stoves)} onChange={handleQuantity} sx={{width: 250}} className="text_field" id="standard-basic" label="Number of Heaters" variant="standard" /></div>
                                <div><TextField value={formatCash(total)} sx={{width: 250}} className="text_field" id="standard-basic" label="total" variant="standard" /></div>

                            </div>

                        </div>  
                    
                        
                    
                    </div> 
                </div>
                <div className="uni_procced_btn">
                    <div className="btn_filled" onClick={ProceedToSerialNumbers}>
                        <div>
                            process sale
                        </div>
                    </div>
                </div>
               
                <EnterSerialNumbers 
                    number={number_of_stoves}
                    state={enter_serial_numbers}
                    set_state={set_enter_serial_numbers} 
                    serial_numbers={serial_numbers}
                    setSerialNumbers={setSerialNumbers}
                    setSnack={setSnack}
                    sellStoves={sellHeaters}
                />
               
            </div>
        </Fade>
    )
}