
import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import '../styles/utils.css'

import main, { formatCash, _DateTime } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import { useOutletContext } from "react-router-dom";
import userPlaceholder from '../resources/icons/id_placeholder.png'



import StovePaymentTable from '../tables/stove_payments_table'
import StoveSalesTable from '../tables/stove_sales_table'
import HeaterSalesTable from '../tables/heater_sales_table'
import PelletSalesTable from '../tables/pellet_sales_table'
import FilterStoveRecords from '../elements/filter_stove_records'
import FilterPelletSalesRecords from '../elements/filter_pellet_sales_records'

import ViewStovePaymentRecord from '../elements/view_stovePayment_records';
import ViewHeaterSale from '../elements/view_heater_sale_record'
import FilterStovePayments from '../elements/filter_stove_payments'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ViewStoveSale from '../elements/view_stove_sale_record'
import ViewPelletSale from '../elements/view_pellet_sale_record'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from "react-router-dom";


export function loader({params}){
    console.log(params)

    return fetch(main.server + `/client_info?i=${params.id}`).then( response =>{
        if(response.ok){
            return response.json()
        }
        throw response
    }).then(res => {
        if(res.request_status){
            
            return res.data
        }
        
    })
      
}  

export default function ViewClient(){
    
    
    const [ tab, setTab ] = useState(1)


    const [data,  ] = useState(useLoaderData())
   
    const [filled, setFilled ] = useState(false)

    const [ history, setHistory ] = useState({})

    const [start, setStart] = useState(false)
    const [client_name, setClientname] = useState('')
    


    const [phone_number1, setPhoneNumber1] = useState('')
    const [phone_number2, setPhoneNumber2] = useState('')
    const [description, setDescription ] = useState('')

    const [geo_coords, setGeoCoords] = useState('')
    
    const [ IdImage, setIDImage ] = useState(false)
   
    const [ location, setLocation ] = useState('')
    const [ region, setRegion ] = useState('')
 
    const [setSnack, setAlert, , user_uid, _actions_ , setNotAllowed  ] = useOutletContext()


    const [id_number, setIdNumber] = useState('')
   


    const [id_type, setIdType] = useState('')

    const [ purchases, setPurchases ] = useState([])
    const [ debts, setDebts ]  = useState([])

    const navigate = useNavigate()

    function handleTabChange(event, new_value){
        if(new_value === 2){
            setStoveSalesPage(0)
            getStoveSalesRecords()
        }   
        if(new_value == 3){
            setStovePaymentPage(0)
            get_stove_payments_records(getStovePaymentForm(0))
        }
        if(new_value == 4){
            setPelletSalesPage(0)
            get_pellet_records(getPelletSalesForm(0))
        }
       
        setTab(new_value)
    } 

    

    function fetchMain(){
       
        fetch(main.server + `/get_client_history?client=${data.UID}`).then(resp => resp.json()).then(result =>{
            setHistory(result.data)
        })
        fetch(main.server + `/get_client_debts?client=${data.UID}`).then(resp => resp.json()).then(result =>{
            setDebts(result.data.debts)
        })
        fetch(main.server + `/get_client_purchases?client=${data.UID}`).then(resp => resp.json()).then(result =>{
            setPurchases(result.data.purchases)
        })

    }


    function modifyClient(elm){
        window.location.assign(`/clients/modify_client_info/UID/${elm.target.dataset.uid}`)
    }


    const [ confrim_state, setConfirmState ] = useState(false)
    const [ confrim_function, setConfirmFunction ] = useState([])


    function _delete_client (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:data.UID}))
  
        fetch( main.server + '/delete_client',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setAlert({ message:'client deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.assign('/clients') }, 3000)
          }
        })
    }



    function closeConfirmDialog(){
        setConfirmState(false)
        setConfirmFunction([])
    }
    
    const delete_messenger = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_client])
    }

    function accept(){
        confrim_function[0]()
    }


    function recordPelletSales(){
        window.location.assign(`/record_pellet_sales/client_name=${data.name}&clientID=${data.UID}`)
        
    }

    function payForStove(){
        window.location.assign(`/pay_for_stove/client_name=${data.name}&clientID=${data.UID}`)
    }



    const [filter_stove_sales_by_startdate, setFilterStoveSalesByStartDate] = useState(false)
    const [filter_stove_sales_by_endDate, setFilterStoveSalesByEndDate] = useState(false)
    const [filter_stove_sales_by_customer, setFilterStoveSalesByCustomer ] = useState(false)
    const [filter_stove_sales_by_paymentplan, setFilterStoveSalesByPaymentPlan] = useState(false)
    const [ filter_stove_sales_by_serialnumber, setFilterStoveSalesBySerialNumber ] = useState(false)
    const [ filter_stove_sales_by_center,  setFilterStoveSalesByCenter ] = useState(false)
   
    const [dtf, setDTF] = useState(false)
    const [stoveSales_startDate, setStoveSalesstartDate] = useState(new Date())
    const [stoveSales_endDate, setStoveSalesEndDate] = useState(new Date())
    const [stoveSales_customer, setStoveSalesCustomer] = useState([])
    const [stoveSales_paymentPlan, setStoveSalesPaymentPlan ] = useState('select')
    const [stoveSales_stoveID, setStoveSalesStoveID ] = useState('')
    const [ stoveSales_center, setStoveSalesCenter] = useState([])
    const [ open_stoveRecords_filter, setStoveRecordsFilterState ] = useState(false)


    const [stove_sales_page, setStoveSalesPage] = useState(0)
    const [ stoveSalesTotal, setStoveSalesTotal ] = useState({})
    const [ stove_sales, setStoveSales ] = useState([])

    const [ downloader_state, setDownloaderState ]  = useState(false)

 


    const [ filter_pellet_sales_by_startDate, setFilterPelletSalesByStartDate] = useState(false)
    const [ filter_pellet_sales_by_endDate, setFilterPelletSalesByEndDate ] = useState(false)
    const [ filter_pellet_sales_by_shop, setFilterPelletSalesByShop ] = useState(false)
    const [ filter_pellet_sales_by_recorder, setFilterPelletSalesByRecorder ] = useState(false)
   

    const [ pelletSales_startDate, setPelletSalesStartDate] = useState(new Date())
    const [ pelletSales_endDate, setPelletSalesEndDate] = useState(new Date())
    const [ pelletfilterByRecorder, setFilterPelletsByRecorder ] = useState('')
    const [ pelletfilterByShop, setFilterPelletsByShop ] = useState('')
   


    const [ pellet_dtf, setPelletDTF] = useState(false)



    const [ pellet_sales_page, setPelletSalesPage] = useState(0)
    const [ pellet_total,setPelletTotal ] = useState({})
    const [ pellet_sales,setPelletSales ] = useState([])
   
    const [ pellet_filterState, setPelletFilterState ] = useState(false)


    const [ filter_stove_payment_by_startDate, setFilterStovePaymentByStartDate] = useState(false)
    const [ filter_stove_payment_by_endDate, setFilterStovePaymentByEndDate ] = useState(false)
    const [ filter_stove_payment_by_client, setFilterStovePaymentByClient ] = useState(false)
    const [ filter_stove_payment_by_recorder, setFilterStovePaymentByRecorder ] = useState(false)
   

    const [ stove_payment_startDate, setStovePaymentStartDate] = useState(new Date())
    const [ stove_payment_endDate, setStovePaymentEndDate] = useState(new Date())
    const [ stovePaymentFilterRecorder, setFilterStovePaymentRecorder ] = useState('')
    const [ stovePaymentFilterClient, setFilterStovePaymentClient ] = useState('')
   


    const [ stove_payment_dtf, setStovePaymentDTF] = useState(false)



    const [ stove_payment_page, setStovePaymentPage] = useState(0)
    const [ stove_payment_total,setStovePaymentTotal ] = useState({})
    const [ stove_payments , setStovePayments ] = useState([])
   
    const [ filter_stove_payment_state , setFilterStovePayment ] = useState(false)





    const [ view_heater_sale, setViewHeaterSale ] = useState(false)
    const [ view_stove_sale, setViewStoveSale ] = useState(false)
    const [ view_pellet_sale, setViewPelletSale ] = useState(false)
    const [ view_pellet_production_record, setViewPelletProductionRecord ] = useState(false)



    const [ view_stove_payment_record, viewStovePaymentRecord ] = useState(false)
    



    const [ check_actions, setCheckActions ]  = useState(false)
    

    const [filter_heater_sales_by_startdate, setFilterHeaterSalesByStartDate] = useState(false)
    const [filter_heater_sales_by_endDate, setFilterHeaterSalesByEndDate] = useState(false)
    const [filter_heater_sales_by_customer, setFilterHeaterSalesByCustomer ] = useState(false)
    const [filter_heater_sales_by_paymentplan, setFilterHeaterSalesByPaymentPlan] = useState(false)
    const [ filter_heater_sales_by_serialnumber, setFilterHeaterSalesBySerialNumber ] = useState(false)

    const [heater_dtf, setHeaterDTF] = useState(false)
    const [heaterSales_startDate, setHeaterSalesstartDate] = useState(new Date())
    const [heaterSales_endDate, setHeaterSalesEndDate] = useState(new Date())
    const [heaterSales_customer, setHeaterSalesCustomer] = useState([])
    const [heaterSales_paymentPlan, setHeaterSalesPaymentPlan ] = useState('select')
    const [heaterSales_heaterID, setHeaterSalesHeaterID ] = useState('')
    const [ open_heaterRecords_filter, setHeaterRecordsFilterState ] = useState(false)


    const [heater_sales_page, setHeaterSalesPage] = useState(0)
    const [ heaterSalesTotal, setHeaterSalesTotal ] = useState(0)
    const [ heater_sales, setHeaterSales ] = useState([])


    const [ stove_sale_payments, setStoveSalePayments ] = useState([])
    const [ stove_sale_payments_total, setStoveSalePaymentsTotal ] = useState([])
    

    const [ view_stove_sale_data, set_stove_sale_data ] = useState({})
    const [ view_heater_sale_data, set_heater_sale_data ] = useState({})
    const [ view_pellet_sale_data, set_pellet_sale_data ] = useState({})
    const [ view_pellet_production_data, set_pellet_production_data ] = useState({})
    const [ view_stove_payment_data, set_view_stove_payment_data ] = useState({})

    const [ payment_plans, setPaymentPlans ] = useState([])

    function getSaleStovePaymentForm(uid){
        const data = {}
        const form = new FormData()
        
        data.sale = uid
        
   
        form.append('form', JSON.stringify(data))
  
        return form
    }

      
    function getStoveSalesForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['client'] = data.UID

        if(filter_stove_sales_by_startdate){
            d['start_date'] = stoveSales_startDate.toDateString()
        }
        if(filter_stove_sales_by_endDate){
            d['end_date'] = stoveSales_endDate.toDateString()
        }
        
        if(filter_stove_sales_by_paymentplan){
            d['payment_plan'] = stoveSales_paymentPlan
        }
        if(filter_stove_sales_by_serialnumber){
            d['stove_id'] = stoveSales_stoveID
        }
        if(filter_stove_sales_by_center){
            d['center'] = stoveSales_center[1]
        }
        if(filter_stove_sales_by_startdate && filter_stove_sales_by_endDate){
            setDTF(true)
        }
        form.append('form', JSON.stringify(d))

        return form
    }


    function getHeaterSalesForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start

        if(filter_stove_sales_by_startdate){
            d['start_date'] = stoveSales_startDate.toDateString()
        }
        if(filter_stove_sales_by_endDate){
            d['end_date'] = stoveSales_endDate.toDateString()
        }
        if(filter_stove_sales_by_customer){
            d['client'] = stoveSales_customer[1]
        }
        if(filter_stove_sales_by_paymentplan){
            d['payment_plan'] = stoveSales_paymentPlan
        }
        if(filter_stove_sales_by_serialnumber){
            d['stove_id'] = stoveSales_stoveID
        }
        if(filter_stove_sales_by_startdate && filter_stove_sales_by_endDate){
            setDTF(true)
        }
        form.append('form', JSON.stringify(d))

        return form
    }

    function getPelletSalesForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['client'] = data.UID
        
        if(filter_pellet_sales_by_startDate){
            d['start_date'] = pelletSales_startDate.toDateString()
        }
        if(filter_pellet_sales_by_endDate){
            d['end_date'] = pelletSales_endDate.toDateString()
        }
        if(filter_pellet_sales_by_shop){
            d['shop'] = pelletfilterByShop[1]
        }
        if(filter_pellet_sales_by_recorder){
            d['recorded_by'] = pelletfilterByRecorder
        }
        if(filter_pellet_sales_by_startDate && filter_pellet_sales_by_endDate){
            setPelletDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function getStovePaymentForm(start){
        const d = {}
        const form = new FormData()

        d['start'] = start
        d['client'] = data.UID
        

        if(filter_stove_payment_by_startDate){
            d['start_date'] = stove_payment_startDate.toDateString()
        }
        if(filter_stove_payment_by_endDate){
            d['end_date'] = stove_payment_endDate.toDateString()
        }
       
        if(filter_stove_payment_by_recorder){
            d['recorded_by'] = stovePaymentFilterRecorder
        }
        if(filter_stove_payment_by_startDate && filter_stove_payment_by_endDate){
            setStovePaymentDTF(true)
        }
        
        form.append('form', JSON.stringify(d))

        return form
    }

    function getStoveSalesRecords(){
        const form = getStoveSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})
        console.log(form)
        get_stove_records(form)
    }

    function getPelletSalesRecords(){
        const form = getPelletSalesForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_pellet_records(form)

        
    }


    function getStovePaymentRecords(){
        const form = getStovePaymentForm(0)
        console.log(form.get('form'))
        setSnack({message:'Fetching records...'})

        get_stove_payments_records(form)

        
    }



    function fetchMoreStoveSales(){
        
        const form = getStoveSalesForm( stove_sales.length > 0 ? stove_sales.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_stove_records(form)
        
    }

    function fetchMoreHeaterSales(){
        
        const form = getHeaterSalesForm( stove_sales.length > 0 ? stove_sales.length - 1 : 0)
        setSnack({message:'Fetching more records...'})
        get_heater_records(form)
        
    }

    function fetchMorePelletSales(){
        
        const form = getPelletSalesForm(pellet_sales.length)
        setSnack({message:'Fetching records...'})
        get_pellet_records(form)
        
    }

    function get_stove_records(form){
        fetch(main.server + '/get_stove_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStoveSales( res.data.list )
               
                setStoveSalesTotal(res.data.totals)
            }
            
        })
    }


    function get_heater_records(form){
        fetch(main.server + '/get_heater_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStoveSales( res.data.list )
               
                setStoveSalesTotal(res.data.totals)
            }
            
        })
    }

    function get_pellet_records(form){
        fetch(main.server + '/get_pellet_sales',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setPelletSales( res.data.list )
                setPelletTotal(res.data.total)
            }
            
        })
    }

    function get_stove_payments_records(form){
        fetch(main.server + '/get_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setStovePayments( res.data.list )
                setStovePaymentTotal(res.data.totals)
            }
            
        })
    }

    function get_stove_sale_payments_records(form){
        fetch(main.server + '/get_stove_payment_records_suid',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
              setStoveSalePayments( res.data.list )
              setStoveSalePaymentsTotal(res.data.totals)
            }
            
        })
    }



    function get_heater_payments_records(form){
        fetch(main.server + '/get_heater_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                
                setHeaterSales( res.data.list )
                setHeaterSalesTotal(res.data.totals)
            }
            
        })
    }

    function StoveRecordsFilter(){
        setStoveRecordsFilterState(true)
    }

    function HeaterRecordsFilter(){
        setHeaterRecordsFilterState(true)
    }
    function FilterPelletSales(){
        setPelletFilterState(true)
    }

    function FilterStovePaymentRecords(){
        setFilterStovePayment(true)
    }

    function viewStoveSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        get_stove_sale_payments_records(getSaleStovePaymentForm(event.target.parentElement.dataset.uid))
        setViewStoveSale(true)
        set_stove_sale_data(stove_sales[key])
        
    }
    function viewHeaterSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewHeaterSale(true)
        set_heater_sale_data(heater_sales[key])
        
    }
    function viewPelletSaleRecord(event){
        const key = event.target.parentElement.dataset.key
        setViewPelletSale(true)
        console.log(key)
        set_pellet_sale_data(pellet_sales[key])
        
    }

    function viewStovePaymentRecord_(event){
        const key = event.target.parentElement.dataset.key
        viewStovePaymentRecord(true)
        console.log(key)
        set_view_stove_payment_data(stove_payments[key])
        
    }

    function downloadStoveRecords() {
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getStoveSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }


    function fetchMoreStovePaymentRecords(){
        
        const form = getStovePaymentForm(stove_payments.length)
        setSnack({message:'Fetching records...'})
        get_stove_payments_records(form)
        
    }

    function downloadStoveRecords() {
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getStoveSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }
    function downloadHeaterRecords() {
        console.log('downloading doc........')
        setDownloaderState(true)
        

        const form = getHeaterSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_heater_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }
    function downloadPelletsalesRecords() {

        setDownloaderState(true)
        
        const form = getPelletSalesForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_pellet_sales_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }
    function downloadStovePaymentRecords() {

        setDownloaderState(true)
        

        const form = getStovePaymentForm(0)
 
        setSnack({message:'Download document...'})

    
        fetch(main.server + '/download_stove_payment_records',{
            method: 'POST',
            body: form
        }).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDownloaderState(false)
                window.location.assign(main.server + `/media/generated_documents/${res.data.file_name}`)
            }else{
                setDownloaderState(false)
                setSnack({message:'download failed'})
            }
            
        })


        
    }

    function close_stove_records_filter(){
        setStoveRecordsFilterState(false)
    }
    function close_pellet_records_filter(){
        setPelletFilterState(false)
    }
    function close_stove_payment_filter(){
        setFilterStovePayment(false)
    }
    function StoveRecordsFilter(){
        setStoveRecordsFilterState(true)
    }

    function HeaterRecordsFilter(){
        setHeaterRecordsFilterState(true)
    }
    function FilterPelletSales(){
        setPelletFilterState(true)
    }
    function FilterStovePaymentRecords(){
        setFilterStovePayment(true)
    }

    function closeStoveRecordViewer(){
        setViewStoveSale(false)
    }
    
    const modify_stove_sale_messenger = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_stove_sale(form)])
    }

    function closeHeaterRecordViewer(){
        setViewHeaterSale(false)
    }

    function _modify_stove_sale(form){
    
        fetch(main.server + '/modify_stove_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    const modify_messenger_heater_sales = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_heater_sale(form)])
    }

    const delete_messenger_heater_sales = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_heater_sale])
    }
    function _modify_heater_sale(form){
    
        fetch(main.server + '/modify_heater_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    function _delete_heater_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_heater_sale_data.UID}))
  
        fetch( main.server + '/delete_heater_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }
    function closePelletRecordViewer(){
        setViewPelletSale(false)
    }
    const modify_messenger_pellet_sales = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_pellet_sale(form)])
    }
    function _modify_pellet_sale(form){
        console.log('last step', form)
        fetch(main.server + '/modify_pellet_sale',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    const delete_messenger_pellet_sales = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_pellet_sale])
    }

    function _delete_pellet_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_pellet_sale_data.UID}))
  
        fetch( main.server + '/delete_pellet_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }
    function closeStovePaymentRecordViewer(){
        viewStovePaymentRecord(false)
    }
    const modify_messenger_stovePayment = (form) => {
        setConfirmState(true)
        setConfirmFunction([() => _modify_stove_payment(form)])
    }
    function _modify_stove_payment(form){
        console.log('last step', form)
        fetch(main.server + '/modify_stove_payment',
            {
              method: "POST",
              body:form,
            }
        ).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
          if(res.request_status){
            setAlert({message:'Record modified'})
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
        
    }

    const delete_messenger_stovePayment = () => {
        setConfirmState(true)
        setConfirmFunction([_delete_stove_payment])
    }

    function _delete_stove_payment (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_stove_payment_data.UID}))
  
        fetch( main.server + '/delete_stove_payment',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }


    const delete_stove_sale_messenger = (uid) => {
        setConfirmState(true)
        setConfirmFunction([() => _delete_stove_sale(uid)])
    }

    function _delete_stove_sale (){
        const form = new FormData()
        form.append('form', JSON.stringify({UID:view_stove_sale_data.UID}))
  
        fetch( main.server + '/delete_stove_sale',
          {
            method:"POST",
            body:form
          }
        ).then( response =>{
          if(response.ok){
              return response.json()
          }
          throw response
        }).then(res => {
          if(res.request_status){
            setSnack({ message:'record deleted' })
            setConfirmState(false)
            setConfirmFunction([])
            setTimeout(() => { window.location.reload() }, 3000)
          }
        })
    }

    function pay_client_debts(){
        navigate(`/pay_client_debts/client_name=${client_name}&clientID=${data.UID}`)
    }


    function renderPage(){
        if(tab === 1){
            return(
                <div className="clientInfoMain">

                   
                      

                    <div className="BtnListcontainer">
                        
                        {_actions_.indexOf('modify_client') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={modifyClient}>
                                    <div>modify client info</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('record_pellet_sales') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" data-uid={data.UID} onClick={recordPelletSales}>
                                    <div>record pellet sales</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('delete_clients') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined btn_red" data-uid={data.UID} onClick={delete_messenger} >
                                    <div>delete client</div>
                                </div>
                            </div>
                        :''}
                        {_actions_.indexOf('pay_client_debts') !== -1 ? 
                            <div className="padv10">
                                <div className="btn outlined" onClick={pay_client_debts} >
                                    <div>pay debts</div>
                                </div>
                            </div>
                        :''}
                         
                    </div>

                    <div className="container">
                        <div className="container_main">
                            
                            <div className="info_value_pair">
                                <div>ID type:</div>
                                <div>{id_type}</div>  
                            </div>
                            <div className="info_value_pair">
                                <div>ID number: </div>
                                <div>{id_number}</div>
                            </div>
                            
                            <div className="info_value_pair">
                                <div>phone number 1:</div>
                                <div>{phone_number1}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>phone number 2:</div>
                                <div>{phone_number2}</div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="container_main">
                            <div className="info_value_pair">
                                <div>Region:</div>
                                <div>{region}</div>
                            </div>
                                        
                            <div className="info_value_pair">
                                <div>Location:</div>
                                <div>{location}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Geo coordinates:</div>
                                <div>{geo_coords}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Description: </div>
                                <div>{description}</div>
                            </div>

                        
                        </div>
                    </div>


                    <div className="container">
                        <div className="container_main">
                            <div className="info_value_pair">
                                <div>Stoves bought: </div>
                                <div>{history.stoves_bought}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Stoves bought on credit: </div>
                                <div>{history.stoves_bought_oncredit}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Unfinished stove payments: </div>
                                <div>{history.unfinished}</div>
                            </div>
                            <div className="info_value_pair">
                                <div>Amount owed: </div>
                                <div>{formatCash(history.amount_owed)}</div>
                            </div>

                            <div className="info_value_pair">
                                <div>Pellet debt: </div>
                                <div>{formatCash(data.pellet_debt)}</div>
                            </div>

                            <div className="info_value_pair">
                                <div>stove payment debt: </div>
                                <div>{formatCash(data.stove_payment_debts)}</div>
                            </div>
                        </div>
                        
                    </div>
                    
                                            
                
                </div>
            )
        }else if(tab === 2){
            return(
                <div className="subTable">
                    <StoveSalesTable 
                        startDate={stoveSales_startDate} 
                        endDate={stoveSales_endDate}
                        dtf={dtf} 
                        page={stove_sales_page} 
                        setPage={setStoveSalesPage} 
                        totals={stoveSalesTotal} 
                        sales={stove_sales} 
                        fetch_more={fetchMoreStoveSales} 
                        filter={StoveRecordsFilter} 
                        view_record={viewStoveSaleRecord}
                        download={downloadStoveRecords}
                    />
                </div>

            )
        }else if(tab === 3){
            return(
                <div className="subTable">
                    <StovePaymentTable
                        startDate={stove_payment_startDate} 
                        endDate={stove_payment_endDate}  
                        dtf={stove_payment_dtf} 
                        page={stove_payment_page} 
                        setPage={setStovePaymentPage} 
                        total={stove_payment_total} 
                        sales={stove_payments} 
                        fetch_more={fetchMoreStovePaymentRecords} 
                        filter={FilterStovePaymentRecords} 
                        view_record={viewStovePaymentRecord_}
                        download={downloadStovePaymentRecords}
                    />
                </div>
            )
        }else if(tab === 4){
            return(
                <div>
                    <PelletSalesTable 
                        startDate={ pelletSales_startDate } 
                        endDate={pelletSales_endDate}  
                        dtf={pellet_dtf} 
                        page={pellet_sales_page} 
                        setPage={setPelletSalesPage} 
                        total={pellet_total} 
                        sales={pellet_sales} 
                        fetch_more={fetchMorePelletSales} 
                        filter={FilterPelletSales} 
                        view_record={viewPelletSaleRecord}
                        download={downloadPelletsalesRecords}
                    />
                </div>
            )
        }else if(tab === 5){
            return(
                <div>
                    <HeaterSalesTable 
                        startDate={heaterSales_startDate} 
                        endDate={heaterSales_endDate}
                        dtf={heater_dtf} 
                        page={heater_sales_page} 
                        setPage={setHeaterSalesPage} 
                        totals={heaterSalesTotal} 
                        sales={heater_sales} 
                        fetch_more={fetchMoreHeaterSales} 
                        filter={HeaterRecordsFilter} 
                        view_record={viewHeaterSaleRecord}
                        download={downloadHeaterRecords}
                    />
                </div>
            )
        }
    }

    useEffect(() => {
        if(!start){
            fetchMain()
            setSnack({
                message: 'page loaded',
            })

            setStart(true)
        }
        if(! filled){
            setClientname(data.name)
            setPhoneNumber1(data.phone_number1)
            setPhoneNumber2(data.phone_number2)
            setDescription(data.description)
            setGeoCoords(data.geo_coords)
            setIDImage(data.id_image)
            setLocation(data.location)
            setRegion(data.region)
            setIdType(data.id_type)
            setIdNumber(data.id_number)
            setFilled(true)
        }
    })
    
    return(
        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                    <div>Client information</div>
                </div>

                <div className="centerPage">

                    <div className="ImageAndName">
                        <div className="clientIdImageContainer">
                            { IdImage ? <img src={ main.server + `/media/${IdImage}`} alt="client id image"/>:
                            <img src={userPlaceholder} alt="client image placeholder"/>}
                        </div>
                        <div id="client_name_view_clients">
                            <div>{client_name}</div>  
                        </div>
                    </div>

                    <div className="centeredTabs spaceOutTabs">
                        <Tabs className='autoTabBody'  value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab value={1} label="General"  />
                            <Tab value={2} label="Stove purchases"  />
                            <Tab value={3} label="Stove payments"  />
                            <Tab value={4} label="Pellet purchases"  />
                        </Tabs>
                    </div>
                    
                    <div>
                       
                        {renderPage()}
                        
                    </div>


                     
                    
                

                </div>

                
                <FilterStoveRecords 
                    state={open_stoveRecords_filter}

                    payment_plans={payment_plans} 
                    filter_stove_sales_by_startdate={filter_stove_sales_by_startdate}
                    filter_stove_sales_by_endDate={filter_stove_sales_by_endDate}
                    filter_stove_sales_by_customer={filter_stove_sales_by_customer}
                    filter_stove_sales_by_paymentplan={filter_stove_sales_by_paymentplan}
                    filter_stove_sales_by_serialnumber={filter_stove_sales_by_serialnumber}
                    
                    setFilterStoveSalesBySerialNumber={setFilterStoveSalesBySerialNumber}
                    setFilterStoveSalesByStartDate={setFilterStoveSalesByStartDate}
                    setFilterStoveSalesByEndDate={setFilterStoveSalesByEndDate}
                    setFilterStoveSalesByCustomer={setFilterStoveSalesByCustomer}
                    setFilterStoveSalesByPaymentPlan={setFilterStoveSalesByPaymentPlan}
                    
                    stoveSales_stoveID={stoveSales_stoveID}
                    setStoveSalesStoveID={setStoveSalesStoveID}

                    stoveSales_startDate={stoveSales_startDate}
                    setStoveSalesstartDate={setStoveSalesstartDate}

                    stoveSales_endDate={stoveSales_endDate}
                    setStoveSalesEndDate={setStoveSalesEndDate}

          
                    stoveSales_customer={stoveSales_customer}
                    setStoveSalesCustomer={setStoveSalesCustomer}

                    stoveSales_paymentPlan={stoveSales_paymentPlan}
                    setStoveSalesPaymentPlan={setStoveSalesPaymentPlan}

                    stoveSales_center={stoveSales_center}
                    filter_stove_sales_by_center={filter_stove_sales_by_center}
                    setStoveSalesCenter={setStoveSalesCenter}
                    setFilterStoveSalesByCenter={setFilterStoveSalesByCenter}

                    getStoveSalesRecords={getStoveSalesRecords}

                    setPage={setStoveSalesPage} 
                    
                    close_stove_records_filter={close_stove_records_filter}
                  

                />


                <FilterPelletSalesRecords 
                    state={pellet_filterState}

                    payment_plans={payment_plans} 
                    filter_pellet_sales_by_startDate={filter_pellet_sales_by_startDate}
                    filter_pellet_sales_by_endDate={filter_pellet_sales_by_endDate}
                    filter_pellet_sales_by_shop={filter_pellet_sales_by_shop}
                    filter_pellet_sales_by_recorder={filter_pellet_sales_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterPelletSalesByStartDate}
                    setFilterPelletSalesByEndDate={setFilterPelletSalesByEndDate}
                    setFilterPelletSalesByShop={setFilterPelletSalesByShop}
                    setFilterPelletSalesByRecorder={setFilterPelletSalesByRecorder}

                    pelletSales_startDate={pelletSales_startDate}
                    setPelletSalesStartDate={setPelletSalesStartDate}

                    pelletSales_endDate={pelletSales_endDate}
                    setPelletSalesEndDate={setPelletSalesEndDate}

                    pelletfilterByRecorder={pelletfilterByRecorder}
                    setFilterPelletsByRecorder={setFilterPelletsByRecorder}

                    pelletfilterByShop={pelletfilterByShop}
                    setFilterPelletsByShop={setFilterPelletsByShop}

                    
                    getPelletSalesRecords={getPelletSalesRecords}
                    setPage={setPelletSalesPage} 

                    close_pellet_records_filter={close_pellet_records_filter}
                />
                

                <FilterStovePayments

                    state={filter_stove_payment_state}

                    filter_pellet_sales_by_startDate={filter_stove_payment_by_startDate}
                    filter_pellet_sales_by_endDate={filter_stove_payment_by_endDate}
                    filter_pellet_sales_by_client={filter_stove_payment_by_client}
                    filter_pellet_sales_by_recorder={filter_stove_payment_by_recorder}

                    setFilterPelletSalesByStartDate={setFilterStovePaymentByStartDate}
                    setFilterPelletSalesByEndDate={setFilterStovePaymentByEndDate}
                    setFilterPelletSalesByClient={setFilterStovePaymentByClient}
                    setFilterPelletSalesByRecorder={setFilterStovePaymentByRecorder}

                    pelletSales_startDate={stove_payment_startDate}
                    setPelletSalesStartDate={setStovePaymentStartDate}

                    pelletSales_endDate={stove_payment_endDate}
                    setPelletSalesEndDate={setStovePaymentEndDate}

                    pelletfilterByRecorder={stovePaymentFilterRecorder}
                    setFilterPelletsByRecorder={setFilterStovePaymentRecorder}

                    pelletfilterByClient={stovePaymentFilterClient}
                    setFilterPelletsByClient={setFilterStovePaymentClient}


                    getPelletSalesRecords={getStovePaymentRecords}
                    setPage={setStovePaymentPage} 

                    close_pellet_records_filter={close_stove_payment_filter}
                />


                <ViewStoveSale 
                    state={view_stove_sale}
                    close={closeStoveRecordViewer}
                    data={view_stove_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_stove_sale_messenger}
                    delete_messenger={delete_stove_sale_messenger}
                    stove_sale_payments={stove_sale_payments}
                    stove_sale_payments_total={stove_sale_payments_total}
                    user_uid={user_uid}
                />


                <ViewHeaterSale 
                    state={view_heater_sale}
                    close={closeHeaterRecordViewer}
                    data={view_heater_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_heater_sales}
                    delete_messenger={delete_messenger_heater_sales}
                    user_uid={user_uid}
                />


                <ViewPelletSale 
                    state={view_pellet_sale}
                    close={closePelletRecordViewer}
                    data={view_pellet_sale_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_pellet_sales}
                    delete_messenger={delete_messenger_pellet_sales}
                />


                <ViewStovePaymentRecord
                    state={view_stove_payment_record}
                    close={closeStovePaymentRecordViewer}
                    data={view_stove_payment_data}
                    alert={setAlert}
                    snack={setSnack}
                    actions={_actions_}
                    modify_messenger={modify_messenger_stovePayment}
                    delete_messenger={delete_messenger_stovePayment}
                />


                <Dialog
                    open={confrim_state}
                    onClose={closeConfirmDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Confirm Action"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to perform this action ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmDialog}>DECLINE</Button>
                        <Button onClick={accept}>
                            ACCEPT
                        </Button>
                    </DialogActions>
                </Dialog>

               
               
              
            </div>
        </Fade>
    )
}