import { formatCash } from "../resources/code/utils"
export default function warning(props){
    return(
        <div id="debtWarning">
            <div className="debtHead">
                <div className="displayText1 padv5">Warning !</div>
                <div>this client has an unfinished debt debts</div>
            </div>
            { props.debts.map((elm, index) => (
                <div key={index} className="stove_debt">
                    <div>
                        <div>stove serial number: </div>
                        <div>{elm.stoveID}</div>
                    </div>
                
                    <div>
                        <div>price: </div>
                        <div>{formatCash(elm.price)}</div>
                    </div>
                    <div>
                        <div>amount paid: </div>
                        <div>{formatCash(elm.amount_paid)}</div>
                    </div>
                    <div>
                        <div>balance: </div>
                        <div>{formatCash( Number(elm.price) - Number(elm.amount_paid))}</div>
                    </div>
                </div>
            ))}
        
        </div>
    )
}