import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'


import Dash, {loader as dashboardLoader} from './pages/dashboard'
import Users, {loader as userLoader} from './pages/users'
import Clients, {loader as clientListLoader} from './pages/clients'
import NewUserForm from './pages/new_user'
import NewClientForm from './pages/new_client'
import SellStoves, {loader as SellStoveLoader } from './pages/sellStove'
import SellPellets from './pages/sellPellets'
import RecordPelletSales, { loader as RecordPelletSalesLoader } from './pages/record_pellet_sales'
import Shops, {loader as shopsLoader} from './pages/centers'
import NewShopForm from './pages/new_center'
import ViewClient, { loader as clientLoader } from './pages/view_client'
import ModifyClient, { loader as modifyClientLoader } from './pages/modify_client_info'
import ViewUser, {loader as userInfoLoader} from './pages/view_user'
import ModifyUserInfo, {loader as modifyUserLoader} from './pages/modify_user'
import Settings , {loader as settingsLoader} from './pages/settings'
//import Records, {loader as RecordsLoader } from './pages/records'
import Records from './pages/records'
import ViewShop, {loader as ShopLoader} from './pages/view_shop'
import ModifyShopInfo, {loader as ModifyShopLoader } from './pages/modify_shop_info'
import Accounting , { loader as AccountingLoader } from './pages/accounting'
import WorkShops, { loader as WorkshopLoader } from './pages/workshops'
import CreateNewWorkShop from './pages/new_workshop'
import RecordPelletProduction , { loader as RecordPelletProductionLoader } from './pages/record_pellet_production'
import ViewWorkShop, { loader as ViewWorkshopLoader} from './pages/view_workshop'
import ViewAccountInfo, { loader as ViewAccountInfoLoader } from './pages/view_account';
import ModifyWorkshop, { loader as ModifyWorkshopLoader } from './pages/modify_workshop';
import SellHeater, { loader as SellHeaterLoader } from './pages/sellHeater'
import PayClientDebts, { loader as PayDebtsLoader } from './pages/pay_client_debts';

import PayForStove, { loader as PayForStoveLoader} from './pages/payForStove';
import Main from './Main'

const baned = [
  { path: 'sell_pellets', element: <SellPellets/>},
]



const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    
    
    children: [
      { index: true, element: <Dash />, loader: dashboardLoader },
      { path: 'users', element: <Users/>, loader: userLoader },
      { path: 'users/new_user', element: <NewUserForm/> },
      { path: 'clients', element: <Clients/>, loader: clientListLoader },
      { path: 'clients/new_client', element: <NewClientForm/>},
      { path: 'sell_stove', element: <SellStoves />, loader: SellStoveLoader },
      { path: 'sell_heater', element: <SellHeater />, loader: SellHeaterLoader },
     
      { path: 'record_pellet_sales/:parameters', element: <RecordPelletSales/>, loader:RecordPelletSalesLoader },
      { path: 'shops', element: <Shops/>, loader: shopsLoader},
      { path: 'shops/new_shop', element: <NewShopForm/> },
      { path: 'clients/view_client/:id', element: <ViewClient/>, loader: clientLoader },
      { path: 'users/view_user/:id', element: <ViewUser/>, loader: userInfoLoader },
      { path: 'account/:id', element: <ViewAccountInfo/>, loader: ViewAccountInfoLoader },
   
      
      { path: 'clients/modify_client_info/UID/:id', element: <ModifyClient/>, loader: modifyClientLoader },
      { path: 'users/modify_user_info/UID/:id', element: <ModifyUserInfo/>, loader: modifyUserLoader },
      { path: 'settings', element: <Settings/>, loader:settingsLoader},
      { path: 'records', element: <Records/> },
      { path: 'shops/view_shop/:id', element: <ViewShop />, loader: ShopLoader},
      { path: 'shops/modify_shop_info/:id', element: <ModifyShopInfo/>, loader:ModifyShopLoader},
      { path: 'accounting', element: <Accounting/>, loader:AccountingLoader },
      { path: 'workshops', element:<WorkShops/>, loader:WorkshopLoader },
      { path: 'workshops/new_workshop', element: <CreateNewWorkShop /> },
      { path: 'record_pellet_production/:parameters', element: <RecordPelletProduction />, loader: RecordPelletProductionLoader },
      { path: 'workshops/view_workshop/:id', element: <ViewWorkShop />, loader:ViewWorkshopLoader },
      { path: 'workshops/modify_workshop/:id', element: <ModifyWorkshop />, loader:ModifyWorkshopLoader },
      { path: 'pay_for_stove/:parameters', element: <PayForStove />, loader: PayForStoveLoader},
      { path: 'pay_client_debts/:parameters', element: <PayClientDebts/>, loader: PayDebtsLoader }
    ],
  },
  
]);



ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
