import { useEffect, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import main, { formatCash, _DateTime, format_cash} from '../resources/code/utils'
import TextField from '@mui/material/TextField';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Paper  from '@mui/material/Paper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useOutletContext } from "react-router-dom";
import ProfileImage from '../resources/icons/profile.png'
import Fade from '@mui/material/Fade'


export function loader({params}){
    console.log(params)
    const parameters = params.parameters.split('&')
    
    const rules = {}
    for(const param of parameters){
        const s = param.split('=')
        if(s.length === 2){
            rules[s[0]] = s[1]
        }
    }

    console.log(rules)

    return rules
}  

export default function PayClientDebts(){
    const [rules,  ] = useState(useLoaderData())
    const [ start, setStart ] = useState(false)
    const [setSnack, setAlert, username,  ] = useOutletContext()
    const [ client_pellet_debts , setClientPelletDebts] = useState([])
    const [ pay_debt_client, setPayDebtClient ] = useState([])
    const [ debtClientSearchRes, setDebtClientSearchRes ] = useState([])
    const [client_search_debt, setClientSearchDebt ]  = useState('')
    const [ amount_to_pay_debt, setAmountToPay ] = useState('')
    const [ debt_payment_date, setDebtPaymentDate ] = useState(new Date())
    const [ total_debt, setTotalDebt ] = useState(0)

    const required_inputs_pellet_payment = [
        //{input: pay_debt_client[1]},
        {input: amount_to_pay_debt},
        {input: debt_payment_date}
    ]
    
    function validate_pelletRecordForm(required_inputs){
        let incomplete = false
        for(const input of required_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }


    function searchForClientDebt(event){
      
        const value = event.target.value
        setClientSearchDebt(value)
        
        if(event.target.value === ''){
            setDebtClientSearchRes([])
            return
        }

        fetch(main.server + `/clients?client=${value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setDebtClientSearchRes(res.data.client_list)
                setPayDebtClient(null)
            }
        })
            
    }
    function selectDebtClient(event){
        const uid = event.target.dataset.uid
        const name = event.target.innerHTML
        setPayDebtClient([name, uid])
        setDebtClientSearchRes([])

        setClientSearchDebt(name)
        fetch(main.server + `/get_client_pellet_debts?client=${uid}`).then(resp => resp.json()).then(result =>{
            setClientPelletDebts(result.data.debts)
            let t = 0
            for(const debt of result.data.debts){
                t += Number(debt.stove_debt) + Number(debt.pellet_debt)
            }
            setTotalDebt(t)
            console.log('total_debt=====================>', t)
        })
      
    }



    

    function handleDebtAmount(event){
        let value = event.target.value

        if(value.search(',') !== -1){
            let clean = value.replaceAll(',','')
            if((Number(clean)|| clean === '' || clean === '0'  ) && Number(clean) <= total_debt ){
                
                clean = Number(clean)
                setAmountToPay(clean)
            
                
            }
        }else{
            if((Number(value) || value === '' || value === '0')  && Number(value) <= total_debt ){
                value = Number(value)
                setAmountToPay(value)
              
            } 
        } 
    }


    function recordPelletPayment(){
        if(!validate_pelletRecordForm(required_inputs_pellet_payment) && pay_debt_client.length === 2 ){
            const form = new FormData()
            const f = {
               
                client: pay_debt_client[1],
                recorded_by: username,
                //description:description,
                amount: amount_to_pay_debt,
                date: debt_payment_date.toDateString(),
            }
         


            form.append('form', JSON.stringify(f))
        

            fetch(main.server + '/record_client_debt_payment',{
                method: 'POST',
                body: form
            }, ).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'debt succefully payed',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/records'),5000)
                }
                else{
                    console.log(result)
                    setAlert({
                        message:result.message,
                        type:'error'
                    })
                }
            })
        }
    }


    useEffect(() => {
        if(!start){
            if(rules.client_name){
                setPayDebtClient([rules.client_name, rules.clientID])
                setClientSearchDebt(rules.client_name)

                fetch(main.server + `/get_client_pellet_debts?client=${rules.clientID}`).then(resp => resp.json()).then(result =>{
                    setClientPelletDebts(result.data.debts)
                    let t = 0
                    for(const debt of result.data.debts){
                        t += Number(debt.stove_debt) + Number(debt.pellet_debt)
                    }
                    setTotalDebt(t)
                    console.log('total_debt=====================>', t)
                })
            }
            setStart(true)
        }
    })



    return(

        <Fade in={true}>
            <div className="page">
                <div className="system_title">
                   <div>Pay client debts</div>
                </div> 
                <div className="centerPage">

                {client_pellet_debts.length ? 
                    <div className='clientDebt pad20'>
            
                        {client_pellet_debts.length ?
                            <div className="padv20">
                                <div>Debts: </div>
                                <div>
                                    {client_pellet_debts.map((elm, index) =>  (
                                        <div className='accordionItem' key={index}>
                                            <Accordion >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1-content"
                                                    id="panel1-header"
                                                >
                                                    <Typography>Client debt {index + 1}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                
                                                
                                                    <div className="info_value_pair">
                                                        <div>pellet payment debt: </div>
                                                        <div>{formatCash(elm.pellet_debt)}</div>
                                                    </div>
                                                    <div className="info_value_pair">
                                                        <div>stove payment debt: </div>
                                                        <div>{formatCash(elm.stove_debt)}</div>
                                                    </div>
                                                    <div className="info_value_pair">
                                                        <div>total: </div>
                                                        <div>{formatCash(Number(elm.stove_debt) + Number(elm.pellet_debt))}</div>
                                                    </div>
                                                    
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>

                                    ))}
                                </div>
                            </div>: ''
                        }
                
                    </div>
                :''}

                <div className="form_main form_centered">   
                    <div className="form_section">    
                        <div className="form_section_main">
                            <div>
                                <div className="authenticatorTitle">Recorded by:</div>
                                <div>
                                    <div className="authenticator_final" >
                                        
                                        <div className="flex_row flex_align_center">
                                            <div className="authenticator_avatar">
                                                <img src={ProfileImage} alt="user image"/>
                                            </div>
                                            <div className="padh10">{username}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div>
                            
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date of sale"
                                        value={debt_payment_date}
                                        variant="standard"
                                        onChange={(newstartDate) => {
                                            setDebtPaymentDate(newstartDate)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div>                              
                                <div><TextField value={client_search_debt} onChange={searchForClientDebt} sx={{width: 250}} className="text_field" id="standard-basic" label="client" variant="standard" /></div>

                                <div className="filterSearchRes">
                                    {debtClientSearchRes.length > 0 ?
                                        <Paper elevation={1} className="searchRBody">
                                            {debtClientSearchRes.map((elm,ind) => (
                                                <div className="generalSearchResult" data-uid={elm.UID} onClick={selectDebtClient} key={ind}>
                                                    {elm.name}
                                                </div>
                                            ))}
                                        
                                        </Paper>
                                    :''}

                                    
                                
                                </div>
                            
                            </div>

                            <div>
                                <div><TextField value={format_cash(amount_to_pay_debt)} onChange={handleDebtAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="amount" variant="standard" /></div>
                            </div>


                            <div className="uni_procced_btn">
                                <div className="btn_filled" onClick={recordPelletPayment}>
                                    <div>
                                        pay debt
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> 

                </div>
            </div>
        </Fade>
    )
        
}