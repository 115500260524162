import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { _DateTime, formatCash } from '../resources/code/utils'
import main from '../resources/code/utils'

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import TextField from '@mui/material/TextField';
import StovePaymentTable from '../tables/stove_payments_table'


export default function ViewStoveSale(props) { 
    const [ data, setData ]  = useState({payment_plans:[], })


    const [customer, setCustomer] = useState()
    const [customer_list, setCustomerList] = useState([])

    const [ stove_serial_number, setStoveSerialNumber ] = useState('')
    const [record_id, setRecordID ] = useState('')
    const [start, setStart] = useState(false)
    const [ mod_record, setModRecord ] = useState(false)
  

    function closeViewer(){

      setCustomer('')
     
      setStoveSerialNumber('')
      setRecordID('')
    
      setModRecord(false)
      setStart(false)
      props.close(false)
    }



    function delete_record(){
      props.delete_messenger(record_id)      
    }



    
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    function serchForClients(elm){
        setCustomer(elm.target.value)
        setAnchorEl(elm.currentTarget)
        fetch(main.server + `/clients?client=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setCustomerList(res.data.client_list)
            }
        })
    }
    
    
    function selectClient(name, uid){
        setCustomer(name)
       
        setAnchorEl(null)
    }



    function modify_stove_sale(){
      const form = new FormData()
  
      const f = {
        UID: record_id,
      }
      if( !customer && !stove_serial_number ){
        props.snack({ message: 'empty form' })
        return
      }
      if(customer){
        f['client'] = customer
      }
      if(stove_serial_number){
        f['serial_number'] = stove_serial_number
      }
      f['modified_by'] = props.user_uid
    
      
  
      form.append('form', JSON.stringify(f))

      props.modify_messenger(form)

    }

   
    
    function modifyRecord(){
      setModRecord(true)
      setCustomer(props.data.client)
      setStoveSerialNumber(props.data.stoveID)
      setRecordID(props.data.UID)
    }
    function back(){
      setModRecord(false)
      setCustomer('')
     
    }

    function handleSerialNumber(event){
      setStoveSerialNumber(event.target.value)
    }
   

    
   

    
    useEffect(() => {
      if(!start){
        fetch(main.server + '/sales_information').then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
              setData(res.data)
            }
            
        })
        console.log(data)

        setStart(true)
      }
     
    })
    
    

    return (
        
        <Dialog
          open={props.state}
          onClose={closeViewer}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="md"
        >
        <DialogTitle id="alert-dialog-title">
            Stove Sale Info
        </DialogTitle>
        <DialogContent>
          {!mod_record?
          <div>
            <div>
              <div className="info_value_pair">
                <div>Date time created: </div>
                <div>{_DateTime(props.data.date_created)}</div>
              </div>
              <div className="info_value_pair padv10">
                <div>Customer: </div>
                <div>{props.data.client}</div>
              </div>
              <div className="info_value_pair">
                <div>Payment plan: </div>
                <div>{props.data.payment_plan}</div>
              </div>
              <div className="info_value_pair">
                <div>Description: </div>
                <div>{props.data.description ? props.data.description : "None" }</div>
              </div>
              <div className="info_value_pair">
                <div>Sold by: </div>
                <div>{props.data.sold_by}</div>
              </div>
              <div className="info_value_pair">
                <div>Record id: </div>
                <div>{props.data.UID}</div>
              </div>
              <div className="info_value_pair">
                <div>Stove serial number: </div>
                <div>{props.data.stoveID}</div>
              </div>
              <div className="info_value_pair">
                <div>Solar panel serial number: </div>
                <div>{props.data.solar_panel_id}</div>
              </div>

              <div className="info_value_pair padv20">
                <div>Price: </div>
                <div>{formatCash(props.data.price)}</div>
              </div>
           

              <div className="padv20 viewRecordActions">
                <div className='btn btn_aqua' onClick={modifyRecord}>
                  <div>modify record</div>
                </div>
                <div className='btn btn_red' onClick={delete_record}>
                  <div>delete record</div>
                </div>
              </div>

            </div>



          </div>
          :

          <div>
            <div className="form_main">
                        
              <div className="form_section">
              
                <div className="form_section_main">


                  <div><TextField disabled value={formatCash(data.stove_price)} sx={{width: 250}} className="text_field" id="standard-basic" label="Stove price" variant="standard" /></div>

                 

                    

                  <div className="flex_row ">
                      <TextField value={customer} onChange={serchForClients} sx={{width: 250}} className="text_field" id="standard-basic" label="Customer" variant="standard" />
                      <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                              'aria-labelledby': 'basic-button',
                          }}
                      >  
                          {customer_list.map((elm,ind) => (
                              <MenuItem key={ind} onClick={() => selectClient(`${elm.first_name} ${elm.middle_name} ${elm.surname}`,elm.UID)}>{elm.first_name} {elm.middle_name} {elm.surname}</MenuItem>
                          ))}
                      </Menu>
                  </div>



              
                  
                  <div><TextField value={stove_serial_number} onChange={handleSerialNumber} sx={{width: 250}} className="text_field" id="standard-basic" label="Stove serial number" variant="standard" /></div>
                  
                </div>

              </div>  
              
              

            </div>
            <div className="padv20 viewRecordActions">
              <div className='btn ' onClick={back}>
                <div>back</div>
              </div>
              <div className='btn btn-success' onClick={modify_stove_sale}>
                <div>modify record</div>
              </div>
                
            </div>
          </div>
        }
        </DialogContent>
        <DialogActions>
            <Button onClick={closeViewer}>
              Close
            </Button>
            
        </DialogActions>
    </Dialog>

  );
}