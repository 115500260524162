import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../styles/utils.css'
import '../styles/users.css'
import { format_cash, formatCash } from '../resources/code/utils'
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';



export default function TransferStoreFunds(props){

    const balance = Number(props.balance)

    const [ start, setStart ] = useState(false) 

    console.log('balance', props.balance, balance)

    
    function handleAmount(event){   


        let value = event.target.value

        console.log('===========================================>',value, balance, value <= balance)

        props.setAmount(value)
        
        
      
    }
    function updateDescription(event){
        props.setDescription(event.target.value)
    }
    function accept(){
        props.accept()
    }



    return(
        <Dialog
            open={props.state}
            onClose={props.close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <div className='pad10'>
                    <div className="info_value_pair padv10">
                        <div>Balanace: </div>
                        <div>{formatCash(balance)}</div>
                    </div>
                    <div className="pad10">
                        <TextField value={format_cash(props.amount)} onChange={handleAmount} sx={{width: 250}} className="text_field" id="standard-basic" label="Amount" variant="standard" />
                    </div>
                    <div className="pad10">
                        <TextField
                            sx={{width: 250}}
                            className="text_field"
                            id="standard-multiline-static"
                            label="transfer description"
                            multiline
                            rows={3}
                        
                            value={props.description}
                            variant="standard"
                            onChange={updateDescription}
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
              
                <Button onClick={accept}>
                    {props.action}
                </Button>
            </DialogActions>
        </Dialog>
    )
}