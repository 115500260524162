import React, { useState, useEffect } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';


export default function EnterSerialNumbers(props) {
    const [ input, setInput ] = useState('31')
    const [ panelInput, setPanelInput ] = useState('41')

    const [ done, setDone ] = useState(false)
    function closeFilter(){
        props.setSerialNumbers([])
        setInput('')
        setPanelInput('')
        props.set_state(false)
        setDone(false)
    }
    function set_input(event){
        setInput(event.target.value)
    }
    function set_panel_input(event){
        setPanelInput(event.target.value)
        
    }
    function proceed(){
        if(props.serial_numbers.length > 0){
            props.sellStoves()
        }
    }
    function enter_number(){
        if(props.serial_numbers.length < Number(props.number)){
            if(input || panelInput){
                props.setSerialNumbers(prev => [ ...prev, { stove: input, panel: panelInput } ])
                setInput('')
                setPanelInput('')
                if(props.serial_numbers.length + 1 === Number(props.number)){
                    console.log('done')
                    setDone(true)
                }
            }
        }else if(props.serial_numbers.length === Number(props.number)){
            props.setSnack({message:'All serial numbers have been entered'})
        }
    }
    function checkSubmit(event){
        if(event.key === 'Enter'){
            enter_number()
        }
    }
    useEffect(()=>{
        
    })


    return (
        
        <Dialog
            open={props.state}
            onClose={closeFilter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title">
                Stove Serial Numbers
            </DialogTitle>
            <DialogContent onKeyDown={checkSubmit}>
                <div>Enter {props.number} serial {props.number > 1 ? 'numbers': 'number' } </div>
                <div className="padv10">
                    {props.serial_numbers.map((elm, ind) => ( 
                        <div className="serialNumber" key={ind}>
                            <div>{ind + 1}. stove: {elm.stove} solar panel: {elm.panel}</div>
                            
                        </div>
                    ) )}
                </div>
                {(!done)?<div className="padv20">
                    <div>
                        <div className='padv10'><TextField autoFocus={true} value={input} onChange={set_input}  className="text_field" id="standard-basic" label="Serial number" variant="standard" /></div>
                        <div className='padv10'><TextField value={panelInput} onChange={set_panel_input}  className="text_field" id="standard-basic" label="Solar panel serial number" variant="standard" /></div>
                    </div>

                    <div className="padv20">
                        <div onClick={enter_number} className="btn">
                            <div>enter number</div>
                        </div>
                    </div>
                </div>: ''}
                
            </DialogContent>
            <DialogActions>
                {(done)? 
                    <div className="pad10">
                        <div className="btn btn-success" onClick={proceed}>
                            <div>Proceed</div>
                        </div>
                    </div>

                :""}
            </DialogActions>
        </Dialog>

  );
}