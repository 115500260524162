
import { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField';
import '../styles/utils.css'

import main, {  Update, format_cash } from '../resources/code/utils'
import Fade from '@mui/material/Fade'
import ProfileImage from '../resources/icons/profile.png'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useOutletContext } from "react-router-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Paper } from '@mui/material';
import { useLoaderData } from "react-router-dom";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export function loader({params}){
    console.log(params)
    const parameters = params.parameters.split('&')
    
    const rules = {}
    for(const param of parameters){
        const s = param.split('=')
        if(s.length === 2){
            rules[s[0]] = s[1]
        }
    }

    console.log(rules)

    return rules
}  


export default function RecordPelletProduction(){
    
    const [rules ] = useState(useLoaderData())
    const [start, setStart] = useState(false)
    const [shop, setShop] = useState('')
    const [startTime, setStartTime] = useState(dayjs('2022-04-17T15:30'))
    const [endTime, setEndTime] = useState(dayjs('2022-04-17T15:30'))
    const [ shop_uid, setShopUID ] = useState('')
    const [amount, setAmount] = useState('')
    const [ diesel_added, setDieselAdded ] = useState('')
    const [setSnack, setAlert, username ] = useOutletContext()
    const [ production_date, setProductionDate ] = useState(new Date()) 
    const [ power_source, setPowerSource ] = useState('generator')
    const [ interval, setInterval] = useState(0)
    const [ machines, setMachines ] = useState('Both')


 
    const [workshop_list, setWorkshopList] = useState([])
    const [description, setDescription ] = useState('')

    const phone_number = 'phone_number'
    const text = 'text'
    const number = 'number'

    

    const required_inputs = [
        {input: shop},
        {input: amount},
        {input: startTime},
        {input: endTime},
        {input: power_source},
       
    ]

    function validate_pelletRecordForm(){
        let incomplete = false
        for(const input of required_inputs){
            if(!input.input){
                incomplete = true
            }
        }
        if(incomplete){
            setSnack({
                message:'incomplete form',
                type:'error'
            })
        }
        return incomplete
    }
    
  
   
    
   
    function searchForWorkshop(elm){
        setShop(elm.target.value)
       
        fetch(main.server + `/workshops?q=${elm.target.value}`).then( response =>{
            if(response.ok){
                return response.json()
            }
            throw response
        }).then(res => {
            if(res.request_status){
                setWorkshopList(res.data.workshops)
            }
        })
    }

    function selectWorkshop(name, uid){
        setShop(name)
        setShopUID(uid)
       
        setWorkshopList([])
    }


    function recordPelletSales(){
        if(!validate_pelletRecordForm()){
            const form = new FormData()
            const f = {
                start: getTime(startTime),
                end: getTime(endTime),
                interval:interval,
                power_source: power_source,
                diesel_added: diesel_added,
                workshop: shop_uid,
                amount: amount,
                machines: machines,
                recorded_by: username,
                description:description,
                date:production_date.toDateString(),
            }
            form.append('form', JSON.stringify(f))
        

            fetch(main.server + '/record_pellet_production',{
                method: 'POST',
                body: form
            }, ).then(response => response.json()).then(result => {
                console.log(result)
                if(result.request_status){
                    setAlert({
                        message:'record succefully created',
                        type:'success'
                    })
                    setTimeout(() => window.location.assign('/records'),5000)
                }
                
            })
        }
    }
    function handleClicks(event){
       
        if(!event.nativeEvent.srcElement.classList.contains('searchSys')){
            setWorkshopList([])
        }
        
    }
    const handlePowerSource = (event: SelectChangeEvent) => {
        setPowerSource( event.target.value )
    }

    const handleMachines = (event: SelectChangeEvent) => {
        setMachines(event.target.value)
    }

    useEffect(() => {
        if(!start){
            setSnack({
                message: 'page loaded',
            })

            if(rules.workshop){
                selectWorkshop(rules.workshop, rules.workshopID)
            }
           

            setStart(true)
        }
    })

    function getTime(time){
        let hour = time.$H 
        const minute = time.$m

        let day_segment = 'AM'
        if(hour > 12){
            hour -= 12
            day_segment = 'PM'
        }

        return `${hour}:${minute} ${day_segment}`
    }

    function convertMillisecondsToHoursAndMinutes(milliseconds) {
        const hours = Math.floor(milliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours}:${minutes}`
    }

    useEffect(() => {
        
        const diff = Math.abs(endTime - startTime)
        const diff_hours = convertMillisecondsToHoursAndMinutes(diff)

        setInterval(diff_hours)

    }, [startTime, endTime])
    return(
        <Fade in={true}>
            <div className="page" onClick={handleClicks}>
                
                
                <div className="system_title">
                    <div>Pellet production information</div>
                </div>


                <div className="form_main form_centered">
                    <div className="form_section">
                        
                        <div className="form_section_main">
                                
                            <div>
                                <div className="authenticatorTitle">Recorded by:</div>
                                <div>
                                    <div className="authenticator_final" >
                                        
                                        <div className="flex_row flex_align_center">
                                            <div className="authenticator_avatar">
                                                <img src={ProfileImage} alt="user image"/>
                                            </div>
                                            <div className="padh10">{username}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            

                            <div>
                              
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileTimePicker 
                                   
                                        label="Start"
                                        value={startTime}
                                        onChange={(newValue) => setStartTime(newValue)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                          
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileTimePicker

                                        label="End"
                                        value={endTime}
                                        onChange={(newValue) => setEndTime(newValue)}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div><TextField disabled value={interval}  sx={{width: 250}} className="text_field" id="standard-basic" label="Time interval" variant="standard" /></div>

                        </div>
                    </div>
        
                        
                    <div className="form_section">
                        <div className="form_section_main">
                            
                   
                            <div><TextField  onChange={(elm) => Update(elm, setDieselAdded, text)} value={(diesel_added)}  sx={{width: 250}} className="text_field" id="standard-basic" label="Diesel added to generator" variant="standard" /></div>
                            
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Power source</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={power_source}
                                    label="ID type"
                                    onChange={handlePowerSource}
                                    variant="standard"
                                >   
                                  
                                    <MenuItem value="generator">Generator</MenuItem>
                                    <MenuItem value="tanesco">Tanesco</MenuItem>
                                 
                                </Select>
                            </FormControl>


                            <div>
                                <TextField value={shop} onChange={searchForWorkshop} sx={{width: 250}} className="text_field" id="standard-basic" label="Workshop" variant="standard" />


                                <div className="filterSearchRes">
                                    {workshop_list.length > 0 ?
                                        <Paper elevation={1} className="searchRBody searchSys">
                                            {workshop_list.map((elm,ind) => (
                                                <div className="generalSearchResult searchSys"  onClick={() => selectWorkshop(elm.name, elm.UID)} key={ind}>
                                                    {elm.name}
                                                </div>
                                            ))}
                                            
                                        </Paper>
                                    :''}

                                    
                                
                                </div>
                            
                            </div>
                        
                            

                            
                            <div><TextField value={format_cash(amount)} onChange={(elm) => Update(elm, setAmount, number)} sx={{width: 250}} className="text_field" id="standard-basic" label="amount in kg" variant="standard" /></div>

                          
                        </div>
                
                    </div>

                    <div className="form_section">
                        <div className="form_section_main">
                            
                            <FormControl >
                                <InputLabel id="demo-simple-select-label">Machines used</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={machines}
                                    label="Machines used"
                                    onChange={handleMachines}
                                    variant="standard"
                                >   
                                  
                                    <MenuItem value="Both">Both</MenuItem>
                                    <MenuItem value="Hammer mill">Hammer mill</MenuItem>
                                    <MenuItem value="Pellet machine">Pellet machine</MenuItem>
                                 
                                </Select>
                            </FormControl>

                            <div>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Production date"
                                        value={production_date}
                                        variant="standard"
                                        onChange={(newstartDate) => {
                                            setProductionDate(newstartDate)
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                            </div>

                            <div>
                                <TextField
                                    sx={{width: 250}}
                                    className="text_field"
                                    id="standard-multiline-static"
                                    label="Record description"
                                    multiline
                                    rows={4}
                                
                                    value={description}
                                    variant="standard"
                                    onChange={(elm) => Update(elm, setDescription, text)}
                                />
                            </div>
                            
                        </div>
                    </div>
                
                   
                

                </div>


                <div className="uni_procced_btn">
                    <div className="btn_filled" onClick={recordPelletSales}>
                        <div>
                            record pellet production
                        </div>
                    </div>
                </div>
            
            </div>

        </Fade>
    )
}